<template>
    <div class="container-cpf">
        <Card style="width: 25em;">
            <template #title> Confirmação de CPF </template>
            <template #content>
                <p>Olá, informe o cpf para liberarmos os exames para download.</p>
                <div style="display: flex; margin-bottom: 10px">
                    <InputMask id="cpf" v-model="form.cpf" mask="999.999.999-99" placeholder="CPF"
                        style="margin-right: 5px" />
                </div>
                <p>*Identificador recebido no Whatsapp.</p>
                <div style="display: flex; margin-bottom: 10px">
                    <InputNumber id="id" v-model="form.id" placeholder="Identificador" style="margin-right: 5px" />
                </div>
                <div style="display: flex; margin-bottom: 10px">
                    <Button label="Enviar" icon="pi pi-check" class="p-button-primary button" :loading="loadingEnviar"
                        @click="enviar" />
                </div>
            </template>
        </Card>
    </div>
    <AppAnexoDialog v-if="openAnexoDialog" style="width: 100vh;" v-model:visible="openAnexoDialog"
        title="Anexos para o Agendamento" tipo="Outro" origem="AGENDAMENTO" :origemId="origemId" :enableImport="false"
        :enableRemove="false" :rotaPublica="true" :cpfFuncionario="form.cpf"
        @onClickCloseAnexo="openAnexoDialog = false" :modal="false" />
</template>

<script>
import { getClientBase } from '../../services/http';

export default {
    data() {
        return {
            disabledAnexoButton: true,
            loadingEnviar: false,
            openAnexoDialog: false,
            origemId: null,
            origem: null,
            form: {}
        };
    },
    methods: {
        async enviar() {
            try {
                console.log(this.form)
                this.loadingEnviar = true;
                const { data } = await getClientBase().post(`agendamento_procedimento/kit-atendimento`, this.form);
                this.origemId = data.origemId;
                this.origem = data.origem;
                this.disabledAnexoButton = false;
                this.loadingEnviar = false;
                this.openAnexoDialog = true;
            } catch (err) {
                this.loadingEnviar = false;
                this.disabledAnexoButton = true;
                this.$toast.add({ severity: 'error', summary: err.response?.data?.message || err?.message, life: 3000 });
            }
        }
    }
};
</script>

<style>
.container-cpf {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
</style>
